.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

.Profile-details {
  max-width: 600px;
  margin: 20px auto;
  text-align: left;
}

.Profile-details h2 {
  margin-bottom: 10px;
}
